<template>
    <slot name="target" :target="(el) => (triggerRef = el)">
    </slot>
    <OModal ref="modal">
        <div class="modal-dialog lookup-dialog modal-dialog-centered modal-dialog-scrollable" data-grid-skip-click-handler>
            <div class="modal-content lookup-content">
                <div class="modal-header">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" :id="`${modalId}-context-checkbox`" v-model="_restrictToContext">
                        <label class="form-check-label" :for="`${modalId}-context-checkbox`">
                            {{ contextToggleLabel ?? $t("Restrict to Context") }}
                        </label>
                    </div>
                    <slot name="toolbarActions"></slot>
                    
                    <div class="hstack ms-auto">
                        <button v-if="!noClear && singleSelectMode" @click="handleClear" class="btn btn-link btn-sm p-0 m-0 me-4">{{ $t("Clear Input") }}</button>                        
                        <button @click="hideModal" type="button" class="btn btn-sm btn-primary" aria-label="Close">{{ $t("Done") }}</button>
                    </div>                    
                </div>
                <div class="modal-body">
                    <OColContainer>
                        <ORowContainer class="col-9">
                            <ODataGrid :data-object="dsObjects" onDemandFields hideGridMenu hideMultiselectColumn hideActionColumn disableSelectAll persistentFilterId="ObjectLookupFilterId" >
                                <OColumn colId="CustomCol" width="40" headerName="" :headerTitle="!singleSelectMode ? $t('Toggle to select/unselect all Objects') : ''" disableMenu>
                                    <template #default="{ row }">
                                        <div class="text-center w-100 h-100">
                                            <input type="checkbox" value="" :id="`${row.ID}-checkbox`" :checked="objectAlreadyAdded(existingObjectsSet, row.ID)" @input="toggleObject($event, row)">
                                        </div>
                                    </template>                                            
                                    <template #headertext>
                                        <div class="text-center w-100 h-100">
                                            <div v-if="isTogglingObjects" class="spinner-border spinner-border-sm" role="status">
                                                <span class="visually-hidden">{{ $t("Loading") }}...</span>
                                            </div>
                                        </div>
                                    </template>
                                </OColumn>
                                <OColumn field="UniqueID" width="120" disableDistinct ></OColumn>
                                <OColumn field="ObjectType" sortField="ObjectType_ID" width="180" sortable></OColumn>
                                <OColumn field="Name" width="300" sortable disableDistinct ></OColumn>
                                <OColumn field="Description" width="300" sortable></OColumn>
                                <OColumn field="OrgUnit" width="230" sortable></OColumn>                                    
                                <OColumn field="SuppliedBy" width="200" hide sortable></OColumn>
                                <OColumn field="InstalledBy" width="200" hide sortable></OColumn>
                                <OColumn field="Component" width="150" hide sortable></OColumn>
                            </ODataGrid>
                            <div v-if="!singleSelectMode" class="w-100 p-2 overflow-y-auto" style="height: 15%; min-height: 15%;">
                                <div v-for="obj in dataObject.data" :key="obj.ID" class="d-inline-block me-3">
                                    <slot name="existingObjectsRow" :row="obj" :removeObject="removeObject">
                                        <div class="hstack gap-1 fw-bold">
                                            <OActionDelete :dataObject="dataObject" :row="obj" class="btn btn-sm btn-link p-0">
                                                <i class="bi bi-x-circle ms-0"></i>
                                            </OActionDelete>
                                            <p class="my-0">{{ obj.Object }}</p>
                                        </div>
                                    </slot>
                                </div>
                            </div>                            
                        </ORowContainer>     
                    </OColContainer>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { OActionDelete } from 'o365-data-components';
import { context } from 'o365-modules';
// import 'o365-nodedata';

const lookupId = crypto.randomUUID();
const defaultView = "aviw_Assets_ObjectsLookup";

const props = defineProps({
    bind: Function,
    dataObject: Object,
    bindingField: {
        type: String,
        default: "Object_ID"
    },
    objectsWhereClause: {
        type: String,
        default: "",
        required: false
    },    
    restrictToContext: {
        type: Boolean,
        default: true,
        required: false
    },
    contextBinding: {
        type: [String, Function],
        required: false
    },
    noClear: {
        type: Boolean,
        default: false
    },
    contextToggleLabel: {
        type: String,
        required: false
    },
    viewName: {
        type:String,
        default: "aviw_Assets_ObjectsLookup" // == defaultView
    }
});

const modal = ref(null);
const _restrictToContext = ref(props.restrictToContext);
const triggerRef = ref(null);
const isTogglingObjects = ref(false);
const hasAddedAllObjects = ref(false);

const modalId = computed(() => `object-lookup-modal-${lookupId}`);
const singleSelectMode = computed(() => !props.dataObject);
const existingObjectsSet = computed(() => new Set(props.dataObject?.data?.map(r => r[props.bindingField]) || []));
const propObjectsWhereClause = computed(() => props.objectsWhereClause);
const propRestrictToContext = computed(() => props.restrictToContext);


const vFields = [
    { "name": "ID" },
    { "name": "PrimKey" },
    { "name": "Name" },
    { "name": "Description" },
    { "name": "ObjectType"},        
    { "name": "ObjectType_ID", "type":"number", "sortOrder": 1,"sortDirection": "asc"},        
    { "name": "TypeAndName" },
    { "name": "SuppliedBy" },
    { "name": "SuppliedBy_ID", "type":"number"},
    { "name": "InstalledBy_ID", "type":"number"},        
    { "name": "InstalledBy"},
    { "name": "OrgUnit" },
    { "name": "OrgUnit_ID", "type":"number"},        
    { "name": "Component" },
    { "name": "Component_ID", "type":"number" },
    { "name": "Status" },
    { "name": "UniqueID" }
];

if(props.viewName === defaultView) {
    vFields.push({ "name": "IsBuilding", "type": "boolean" });
    vFields.push({ "name": "IsRoom", "type": "boolean"  });
    vFields.push({ "name": "IsSite", "type": "boolean"  });
    vFields.push({ "name": "IsLocation", "type": "boolean"  });
    vFields.push({ "name": "IsSystem", "type": "boolean"  });
    vFields.push({ "name": "IsArea", "type": "boolean"  });
}

const dsObjects = $getOrCreateDataObject({
    "id": `o365-system-lookups-dsObjLookup`,
    "viewName": props.viewName,
    "distinctRows": false,
    "disableAutoLoad":true,
    "selectFirstRowOnLoad": true,
    "fields": vFields,
    "maxRecords": 50,
    "dynamicLoading": true,
    "definitionProc": "astp_Assets_ObjectsLookupDefintion",
    "enableProperties": props.viewName === defaultView,
    "disableLayouts": false
});

dsObjects.filterObject.setColumnDistinctOptions("ObjectType", { targetColumn:"ObjectType_ID" });
dsObjects.filterObject.setColumnDistinctOptions("OrgUnit", { targetColumn:"OrgUnit_ID" });
dsObjects.filterObject.setColumnDistinctOptions("SuppliedBy", { targetColumn:"SuppliedBy_ID" });
dsObjects.filterObject.setColumnDistinctOptions("InstalledBy", { targetColumn:"InstalledBy_ID" });
dsObjects.filterObject.setColumnDistinctOptions("Component", { targetColumn:"Component_ID" });

if(props.viewName != defaultView){
    dsObjects.recordSource.expandView = true;
    dsObjects.recordSource.definitionProc = "astp_Assets_ObjectsLookupDefinition"
}

if (singleSelectMode.value && !props.bind) {
    console.warn("Single select mode is active, but no bind function was passed into props. Bind function must be passed in single select mode");
}

onMounted(() => {
    triggerRef.value.addEventListener("click", showModal);
});

// Context toggle listener
watch(_restrictToContext, toggleContextFiltering);

// If the prop changes, we set the internal variable to whatever the prop is
watch(propRestrictToContext, restrictToContext => (_restrictToContext.value = restrictToContext));

// Object where clause prop
watch(propObjectsWhereClause, wc => {
    dsObjects.recordSource.whereClause = wc ;
    if (dsObjects.state.isLoaded) {
        dsObjects.recordSource.loadIfWhereClauseChanged();
    }    
}, { immediate: true });

function toggleObject(evt, row) {
    if (evt.target.checked) {
        if (singleSelectMode.value) {
            setTimeout(() => (evt.target.checked = false), 100);
            props.bind(row);                        
            hideModal();
        } else if (objectAlreadyAdded(existingObjectsSet.value, row.ID)) {
            removeObjectById(row.ID);
            evt.target.checked = false;
        } else {
            createNewRow({ [props.bindingField]: row.ID });
        }
    } else {
        removeObjectById(row.ID);
    }
}

function toggleContextFiltering(restrictToContext) {
    if(props.viewName == "aviw_Assets_ObjectsLookup"){
        if (restrictToContext) {
            if (props.contextBinding) {
                if (typeof props.contextBinding === "function") {
                    dsObjects.enableContextFilter(props.contextBinding);
                } else if (typeof props.contextBinding === "string") {
                    dsObjects.enableContextFilter({ idPathField: props.contextBinding });
                }
            } else {
                dsObjects.enableContextFilter();
            }
        } else {
            dsObjects.enableContextFilter(null);
        }
    }else{
        if(restrictToContext){
            dsObjects.recordSource.contextId = context.id;
        }else{
            dsObjects.recordSource.contextId = null;
        }
    }
    dsObjects.load();
}

function removeObject(row) {
    row.delete();
}

function removeObjectById(objectId) {
    const existingObjectRow = props.dataObject?.data?.find(o => o[props.bindingField] === objectId);
    if (existingObjectRow) {
        removeObject(existingObjectRow);
    }        
}

async function createNewRow(rowData) {
    const newRow = props.dataObject.createNew(rowData);
    return newRow.save();
}

function objectAlreadyAdded(existingObjectsSet, objectId) {
    return existingObjectsSet.has(objectId);
}

async function toggleAllObjects(evt) {
    isTogglingObjects.value = true;
    try {
        const objects = await dsObjects.retrieve({ fields: [{ name: "ID" }], maxRecords: 1000 /* We need a better batch approach. Limited to 1k for now */ });
        if (evt.target.checked) {            
            // Add all            
            const promises = objects.filter(o => !existingObjectsSet.value.has(o.ID)).map(o => createNewRow({ [props.bindingField]: o.ID }));
            await Promise.allSettled(promises);
        } else {
            // Delete all
            const promises = objects.filter(o => existingObjectsSet.value.has(o.ID)).map(o => props.dataObject?.data?.find(i => i[props.bindingField] === o.ID)?.delete());
            await Promise.allSettled(promises);
        }
    } catch(err) {
        throw err;
    } finally {
        isTogglingObjects.value = false;
        hasAddedAllObjects.value = !hasAddedAllObjects.value;
    }    
}

function showModal() {
    modal.value?.show();
    toggleContextFiltering(_restrictToContext.value);
    //dsObjects.recordSource.loadIfWhereClauseChanged();
}

function hideModal() {
    modal.value?.hide();
}

function handleClear() {
    if (!props.bind) {
        return;
    }
    
    const nullObject = new Proxy({}, {
        get: function (_target, _prop) {
            return null;
        }
    });
    props.bind(nullObject);
    hideModal();
}
</script>

<style scoped>
    .lookup-dialog {
        max-width: 90% !important;
    }

    .lookup-content {
        height: 90% !important;
    }
</style>